import React from 'react';

import { Helmet } from 'react-helmet';
import { Footer } from './Footer';
import { Header } from './Header';

interface PageLayoutProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const PageLayout = ({
  title,
  description,
  children,
}: PageLayoutProps) => {
  return (
    <>
      <Helmet>
        {title && <title>{title} | FragFrauFreitag</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>
      <Header />
      <div className="container mx-auto px-6 pb-6 w-full max-w-prose">
        <main className="mb-12">{children}</main>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
};
