import React from 'react';

export const Footer = () => {
  return (
    <div className="text-center">
      <div className="w-20 border-b-2 rounded border-slate-200 mx-auto mb-2"></div>
      <span className="text-xs text-slate-400">
        © {new Date().getFullYear()}, Kafi Freitag – Built with Gatsby
      </span>
    </div>
  );
};
