import React from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'gatsby';
import { Footer } from './Footer';

interface MainNavigationProps {
  container: HTMLDivElement;
}

export const MainNavigation = ({ container }: MainNavigationProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const hamburgerIcon = (
    <svg
      className="cursor-pointer"
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      onClick={() => setIsMenuOpen(true)}
    >
      <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
    </svg>
  );

  const closeIcon = (
    <svg
      className="cursor-pointer"
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      onClick={() => setIsMenuOpen(false)}
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  );

  const menuItems = [
    {
      link: '/',
      label: 'Alle Antworten',
    },
    {
      link: '/ueber-frau-freitag',
      label: 'Über Frau Freitag',
    },
  ];

  const secondaryMenuItems = [
    {
      link: 'https://kafifreitag.com',
      label: 'kafifreitag.com',
    },
  ];

  return (
    <>
      {isMenuOpen &&
        createPortal(
          <div
            className={`${
              isMenuOpen
                ? 'fixed bg-white top-0 left-0 z-10 w-full h-full'
                : 'relative'
            }`}
          >
            <div className="w-full h-full max-w-prose p-6 mx-auto">
              <div className="flex flex-col h-full gap-20">
                <div className="flex-1">
                  <ul className="grid grid-cols-1 justify-items-center gap-6 mt-40">
                    {menuItems.map((item) => {
                      return (
                        <li key={item.link}>
                          <Link
                            to={item.link}
                            onClick={() => setIsMenuOpen(false)}
                            className="text-2xl font-semibold hover:text-pink underline decoration-4 decoration-pink"
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="flex-1">
                  <ul className="grid grid-cols-1 justify-items-center gap-3">
                    {secondaryMenuItems.map((item) => {
                      return (
                        <li key={item.link}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-md text-slate-400 hover:text-pink underline decoration-slate-300 after:content-['_↗']"
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="flex-none">
                  <Footer />
                </div>
              </div>
            </div>
          </div>,
          container
        )}
      <div className="z-50 relative">
        {isMenuOpen ? closeIcon : hamburgerIcon}
      </div>
    </>
  );
};
