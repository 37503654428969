import * as React from 'react';
import { Link } from 'gatsby';
import { PageLayout } from '../components/PageLayout';

const NotFoundPage = () => {
  return (
    <PageLayout
      title="Seite nicht gefunden"
      description="Die angeforderte Seite konnte nicht gefunden werden."
    >
      <h1 className="text-xl font-semibold">Seite nicht gefunden</h1>
      <p className="mt-6">
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        wir konnten die Seite nicht finden.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link
          to="/"
          className="font-semibold underline decoration-2 decoration-pink"
        >
          Zur Startseite
        </Link>
      </p>
    </PageLayout>
  );
};

export default NotFoundPage;
