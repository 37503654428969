import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'gatsby';

import { SearchWidget } from './SearchWidget';
import { MainNavigation } from './MainNavigation';

import Logo from '../images/logo-fff.svg';

export const Header = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [headerEl, setHeaderEl] = useState<HTMLDivElement>();
  const [navEl, setNavEl] = useState<HTMLDivElement>();

  const headerRef = useCallback(
    (node) => {
      if (node !== null) {
        setHeaderEl(node);
      }
    },
    [setHeaderEl]
  );

  const navRef = useCallback(
    (node) => {
      if (node !== null) {
        setNavEl(node);
      }
    },
    [setNavEl]
  );

  useEffect(() => {
    function scrollCallback() {
      if (isSticky !== window.scrollY > 60) {
        setIsSticky(!isSticky);
      }
    }

    if (headerEl) {
      document.addEventListener('scroll', scrollCallback);

      return () => {
        document.removeEventListener('scroll', scrollCallback);
      };
    }
  }, [headerEl, isSticky, setIsSticky]);

  return (
    <>
      <div ref={navRef}></div>
      <div
        ref={headerRef}
        className={`z-20 sticky top-0 bg-gradient-to-b from-white transition-transform ${
          isSticky
            ? 'shadow-sm -translate-y-24 to-white'
            : 'translate-y-0 via-white/100 to-white/0'
        }`}
      >
        <div
          className={`container mx-auto p-6 w-full max-w-prose transition-transform ${
            isSticky ? 'translate-y-12' : 'translate-y-0'
          }`}
        >
          <header>
            <div className="grid grid-cols-3 justify-between items-center">
              <nav className="justify-self-start">
                {navEl && <MainNavigation container={navEl} />}
              </nav>
              <div className={`justify-self-center`}>
                <Link to="/" className="leading-none">
                  <img
                    className={`transition-transform ${
                      isSticky ? 'scale-50' : 'scale-100'
                    } w-24`}
                    alt="FragFrauFreitag Logo"
                    src={Logo}
                  />
                </Link>
              </div>
              <div className="justify-self-end">
                <SearchWidget />
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};
